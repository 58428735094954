import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { DetailsComponent } from './components/dashboard/details/details.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { AlertsComponent } from './components/shared/alerts/alerts.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeadersInterceptor } from 'src/interceptors/headers.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './components/lookup/table/table.component';
import { ListComponent } from './components/lookup/list/list.component';
import { AddTableComponent } from './components/lookup/add-table/add-table.component';
import { LoaderInterceptor } from 'src/interceptors/loader.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    DetailsComponent,
    HeaderComponent,
    AlertsComponent,
    LoaderComponent,
    TableComponent,
    ListComponent,
    AddTableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
