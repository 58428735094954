<main class="dashboard-container container">
  <div class="mb-3">
    <h2>Lookup Tables</h2>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-4 mb-4" *ngFor="let item of lookupTables">
      <div
        class="lookup-card"
        routerLink="/lookup-table"
        [routerLink]="'/lookup-table'"
        [queryParams]="{ name: item }"
      >
        <h6>{{ item }}</h6>
        <i class="fa-regular fa-angle-right"></i>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 mb-4">
      <div
        class="lookup-card justify-content-center gap-2"
        (click)="openModal(addTable)"
      >
        <h6>Add Table</h6>
        <i class="fa-regular fa-plus"></i>
      </div>
    </div>
  </div>
</main>

<ng-template #addTable let-modal>
  <div class="modal-header justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">Add Table</h4>
    <i class="fa-light fa-xmark" (click)="cancelChanges()"></i>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <div class="form-group mb-4">
          <label class="form-label">Name</label>
          <input
            type="text"
            placeholder="Table name"
            class="form-control"
            onkeypress="return event.charCode != 32"
            [(ngModel)]="tableName"
            [ngModelOptions]="{ standalone: true }"
            (keyup)="isAlreadyExists = false"
          />
          <small
          class="form-text font-12 text-danger"
          *ngIf="isClicked && tableName.trim() === ''"
          >Table name cannot be empty</small
        >
        <small
          class="form-text font-12 text-danger"
          *ngIf="isClicked && !isTableNameValid() && tableName.trim() !== ''"
          >Table name should start with an alphabet</small
        >
        <small
          class="form-text font-12 text-danger"
          *ngIf="isAlreadyExists && tableName.trim() !== ''"
          >Table name already exists, please use a new Table name</small
        >
        </div>
        <div class="form-group mb-4">
          <label class="form-label">No. of Columns</label>
          <input
            type="number"
            placeholder="No of columns"
            [(ngModel)]="columns"
            [ngModelOptions]="{ standalone: true }"
            class="form-control"
          />
        </div>
        <div class="form-group mb-4">
          <label class="form-label">No. of Rows</label>
          <input
            type="number"
            placeholder="No of rows"
            [(ngModel)]="rows"
            minlength="1"
            [ngModelOptions]="{ standalone: true }"
            class="form-control"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onAddTable()">
      Add
    </button>
  </div>
</ng-template>
