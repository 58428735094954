import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

interface Shipments {
  exception: string;
  threat_level: string;
  threat_management: string;
  datestamp: string;
  route_risk: string;
  load_id: string;
  id: number;
  inspection: string;
  timestamp: string;
}
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
})
export class DetailsComponent implements OnInit {
  shipmentsList: Shipments[] = [];

  items: any[] = [
    {
      label: 'Load assign data',
      subItems: [
        {
          class: 'col-lg-6',
          value: 'Shipper',
        },
        {
          class: 'col-lg-6',
          value: 'Sec cert',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'assign',
        },
        {
          class: 'col-lg-6',
          value: 'timestamp',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'seaport',
        },
        {
          class: 'col-lg-6',
          value: 'port code',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
      ],
    },
    {
      label: 'carrier data',
      subItems: [
        {
          class: 'col-lg-12',
          value: 'carrier',
        },
        {
          class: 'col-lg-12',
          value: '-',
        },
        {
          class: 'col-lg-12',
          value: 'sec cert',
        },
        {
          class: 'col-lg-12',
          value: '-',
        },
        {
          class: 'col-lg-12',
          value: 'driver"s license',
        },
        {
          class: 'col-lg-12',
          value: '-',
        },
      ],
    },
    {
      label: 'load cnsig data',
      class: 'col-lg-6',
      subItems: [
        {
          class: 'col-lg-6',
          value: 'receiver',
        },
        {
          class: 'col-lg-6',
          value: 'Sec cert',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'consign',
        },
        {
          class: 'col-lg-6',
          value: 'time stamp',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'lane id',
        },
        {
          class: 'col-lg-6',
          value: 'load id',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
      ],
    },
  ];
  showLoadIdDetails: boolean = false;
  start: number = 1;
  size: number = 10;
  page: number = 1;
  pageSize: number = 10;
  totalItems: number;
  loadDetails: any;

  constructor(private el: ElementRef, private dataService: DataService) {}
  ngOnInit(): void {
    this.getLoadIdDetails(this.start, this.size);
  }

  getLoadIdDetails(incomingStart: number, incomingSize: number) {
    let body = {
      start: incomingStart,
      size: incomingSize,
    };
    this.dataService.getAllLoads(body).subscribe({
      next: (result) => {
        this.shipmentsList = result.data.data;
        this.totalItems = result.data.total;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  transformData(realData: any): any[] {
    return [
      {
        label: 'Load assign data',
        subItems: [
          { class: 'col-lg-6', value: 'Shipper' },
          { class: 'col-lg-6', value: 'Sec cert' },
          { class: 'col-lg-6', value: realData.loadAssignData.shipper },
          { class: 'col-lg-6', value: realData.loadAssignData.sec_cert },
          { class: 'col-lg-6', value: 'Assign' },
          { class: 'col-lg-6', value: 'Timestamp' },
          { class: 'col-lg-6', value: realData.loadAssignData.assign },
          { class: 'col-lg-6', value: realData.loadAssignData.timestamp },
          { class: 'col-lg-6', value: 'Seaport' },
          { class: 'col-lg-6', value: 'Port code' },
          { class: 'col-lg-6', value: realData.loadAssignData.seaport },
          { class: 'col-lg-6', value: realData.loadAssignData.port_code },
        ],
      },
      {
        label: 'carrier data',
        subItems: [
          { class: 'col-lg-12', value: 'Carrier' },
          { class: 'col-lg-12', value: realData.carrierData.carrier },
          { class: 'col-lg-12', value: 'Sec cert' },
          { class: 'col-lg-12', value: realData.carrierData.sec_cert },
          { class: 'col-lg-12', value: 'Driver\'s license' },
          { class: 'col-lg-12', value: realData.carrierData["driver's_licence"] },
        ],
      },    
      {
        label: 'Load cnsig data',
        subItems: [
          { class: 'col-lg-6', value: 'Receiver' },
          { class: 'col-lg-6', value: 'Sec cert' },
          { class: 'col-lg-6', value: realData.loadConsignData.receiver },
          { class: 'col-lg-6', value: realData.loadConsignData.sec_cert },
          { class: 'col-lg-6', value: 'Consign' },
          { class: 'col-lg-6', value: 'Time stamp' },
          { class: 'col-lg-6', value: realData.loadConsignData.consign },
          { class: 'col-lg-6', value: realData.loadConsignData.timestamp },
          { class: 'col-lg-6', value: 'Lane id' },
          { class: 'col-lg-6', value: 'Load id' },
          { class: 'col-lg-6', value: realData.loadConsignData.lane_id },
          { class: 'col-lg-6', value: realData.loadConsignData.load_id },
        ],
      },
    ];
  }
  

  showDetails(sectionId: string, incomingMatrixId: string) {
    this.getLoadDetails(incomingMatrixId)
    this.showLoadIdDetails = true;
    setTimeout(() => {
      const element = this.el.nativeElement.querySelector(`#${sectionId}`);
      if (element) {
        const targetScrollPosition = element.offsetTop - 100;

        window.scrollTo({
          top: targetScrollPosition,
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  getLoadDetails(incomingid) {        
    this.dataService.getLoadDetails({"loadId" : incomingid}).subscribe({
      next: (result) => {
        if (result.status === 'failed') {
          // this.displayAlertMessage(result.message, 'error', 'danger');
        } else {
          this.loadDetails = result?.data;
          this.items = this.transformData(this.loadDetails);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getPaginationFromServer(incomingPage: number) {
    this.page = incomingPage;
    this.start = (incomingPage - 1) * this.size + 1;
    this.getLoadIdDetails(this.start, this.size);
  }
}
