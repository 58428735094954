<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
<div class="bg">
  <div class="auth-card p-5">
    <div class="">
      <div class="">
        <div class="text-center">
          <img
            class="text-center"
            src="../../../../assets/images/logo.png"
            alt=""
          />
        </div>
        <h2 class="text-center mb-4 mt-4">Forgot Password</h2>
        <p class="text-center">Enter you email below to reset your password</p>
        <form class="Input-form mt-4" [formGroup]="forgotPasswordForm">
          <div>
            <div class="form-outline mb-4">
              <div class="form-outline mb-4">
                <div class="input-box">
                  <i class="fa-light fa-envelope"></i>
                  <input
                    type="email"
                    placeholder="Email"
                    class="form-control"
                    formControlName="email"
                  />
                  <small
                    *ngIf="
                      forgotPasswordForm.controls['email'].hasError('email') &&
                      forgotPasswordForm.controls['email'].touched
                    "
                    class="text-danger font-13"
                    >Email is invalid</small
                  >
                  <small
                    class="text-danger font-12"
                    *ngIf="forgotPasswordForm.get('email')?.errors?.['required'] && forgotPasswordForm.get('email')?.touched"
                    >Email is required</small
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="button">
            <button
              type="submit"
              class="btn py-2 d-block btn-primary btn-lg w-100 position-relative"
              (click)="loader ? '' : forgotPassword()"
            >
              <div class="lds-roller" *ngIf="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span [ngClass]="!loader ? '' : 'not-visible'">Continue</span>
            </button>
          </div>

          <div class="text-center mt-3">
            <a class="link" href="javascript:void(0)" [routerLink]="['/login']">
              <i class="fa-light fa-arrow-turn-left mx-2"></i>
              Back to Login</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
