import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SessionService } from 'src/app/services/session.service';
import * as notesV1 from '../../../../assets/ReleaseNotesV1.json';
import * as notesV2 from '../../../../assets/ReleaseNotesV2.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm : FormGroup;
  lReleaseNotes: {} = [];
  loader : boolean = false;
  // passwordVisible:boolean=false;
  displayErrorBlock: boolean = false;
  resMessage: { message: any; responseType: any; color: any; };
  constructor(private sessionService : SessionService, private authservice : AuthService, private router : Router) { 
    this.lReleaseNotes = environment.branch=='development/1.0' ? notesV1: notesV2;
    console.warn(
      `Latest Release Version: `,
      this.lReleaseNotes[0]?.ReleaseVersion
    );
    let accessToken = this.sessionService.getAccessToken();
    if(accessToken){
      this.router.navigateByUrl('/dashboard');
    }
  }

  ngOnInit(): void {
    this.initializeLoginForm()
  }

  
  // -------------------- INITIALIZE LOGIN FORM --------------------
  initializeLoginForm() {
    this.loginForm = new FormGroup({
      userId:new FormControl(null,[Validators.required]),
      password: new FormControl(null,[Validators.required])
    })
  }

  // togglePasswordVisibility() {
  //   this.passwordVisible = !this.passwordVisible;
  // }

  sendLoginRequest(){
    if(this.loginForm.valid) {
      this.loader=true;
      this.authservice.login(this.loginForm.value).subscribe({
        next: (result) => {
          if(result.status=='connected') {
            this.sessionService.setAccessToken(result.accessToken);
            result.user.pictureUrl=null;
            this.sessionService.setUserDetails(result.user);
            this.router.navigateByUrl('/dashboard');
          } else {
            this.displayAlertMessage('Bad Credentials!', 'error', 'danger');
            console.error("LoginComponent_SendLoginRequest: Error ===>>", result);
            this.loader=false;
          }
        },
        error: (error) => {
          this.loader=false;
          console.error("LoginComponent_SendLoginRequest: ERROR ===>>", error);
          this.displayAlertMessage('Bad Credentials!', 'error', 'danger');
        }
      })
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

     // -------------------- ALERT MESSAGES --------------------
     displayAlertMessage(incommingMessage, incommingResponseType,incommingColor) {
      this.displayErrorBlock = true
      this.resMessage = {
        message: incommingMessage,
        responseType : incommingResponseType,
        color :  incommingColor
      };
      setTimeout(() => { this.displayErrorBlock = false; }, 3000);
    }
  }
