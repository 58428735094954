import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss'],
})
export class AddTableComponent implements OnInit {
  params: any;
  list: any[] = [];
  columnNames: any[] = [];
  editableColumnNames: string[] = [];
  isEditMode = false;
  hasQueryParams = false;
  page = 4;

  // Store the original data
  private originalColumnNames: string[] = [];
  private originalList: { columns: { [key: string]: string } }[] = [];
  displayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any; };
  backOrCancel: any;

  constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router) {}

  private modalService = inject(NgbModal);

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      console.log(params);
      
      this.updateTable();
    });
  }

  updateTable() {
    this.hasQueryParams = true;
    this.columnNames = [];
    this.list = [];

    for (let i = 0; i < this.params.columns; i++) {
      const columnName = 'Column_' + (i + 1);
      this.columnNames.push(columnName);
      this.editableColumnNames.push(columnName);
    }

    for (let i = 1; i <= this.params.rows; i++) {
      const row: { [key: string]: string } = {};
      this.columnNames.forEach((columnName) => {
        row[columnName] = `row ${i}`;
      });
      this.list.push(row);
    }

    this.originalColumnNames = [...this.columnNames];
    this.originalList = JSON.parse(JSON.stringify(this.list));
  }

  getColumnNames(): string[] {
    return this.columnNames;
  }

  // toggleEditMode() {
  //   if (this.isEditMode) {
  //     this.saveChanges(); // Optionally save changes when switching back to view mode
  //   }
  //   this.isEditMode = !this.isEditMode;
  //   if (!this.isEditMode) {
  //     this.updateTable(); // Ensure table updates with new column names
  //   }
  // }

  saveChanges() {
    // if (this.isEditMode) {
      // Clone the new column names
      const newColumnNames = [...this.editableColumnNames];

      // Update each row with the new column names
      const updatedList = this.list.map((row) => {
        const updatedRow = {}; // Create a new row object

        // Iterate over the original column names to map them to new column names
        this.originalColumnNames.forEach((oldColName, index) => {
          const newColName = newColumnNames[index];
          if (newColName) {
            // Map the old column value to the new column name, or use an empty string if no value
            updatedRow[newColName] = row[oldColName] || '';
          }
        });

        return updatedRow;
      });

      // Update the state with the new column names and the updated list
      this.columnNames = newColumnNames;
      this.list = updatedList;
      let body = {
        tableName: this.params.name,
        columns: this.columnNames,
        data: this.list,
      };
      this.dataService.addLookupTable(body).subscribe({
        next: (result) => {
          if(result.status=='failed'){
            this.displayAlertMessage(result.message, 'error', 'danger');
          } else {
            // this.isEditMode=false;
            this.displayAlertMessage('Table created successfully', 'success', 'success');
            setTimeout(() => {
              this.router.navigate(['/lookup-list']);
            }, 2000);
          }
        },
        error: (error) => {
          console.error("AddTableComponent_SendAddLookupTableRequest: ERROR ===>>", error);
        }
      })
    // }
    }

  cancelChanges() {
    // this.isEditMode = false;
    // this.columnNames = [...this.originalColumnNames];
    // this.list = JSON.parse(JSON.stringify(this.originalList)); // Restore original list
    this.router.navigate(['/lookup-list']);
  }

  isColumnNameInvalid(columnName: string): boolean {
    return !/^[a-zA-Z_]/.test(columnName);
  }  

  isAnyColumnNameInvalid(): boolean {
    return this.editableColumnNames.some(name => this.isColumnNameInvalid(name));
  }

  cancelEdits(cancel: any, check: any) {
    this.backOrCancel=check
    this.modalService.open(cancel, { centered: true });
  }


     // -------------------- ALERT MESSAGES --------------------
     displayAlertMessage(incommingMessage, incommingResponseType,incommingColor) {
      this.displayErrorBlock = true
      this.resMessage = {
        message: incommingMessage,
        responseType : incommingResponseType,
        color :  incommingColor
      };
      setTimeout(() => { this.displayErrorBlock = false; }, 3000);
    }
}
