import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { DetailsComponent } from './components/dashboard/details/details.component';
import { canActivateTeam } from './guards/auth.guard';
import { ListComponent } from './components/lookup/list/list.component';
import { TableComponent } from './components/lookup/table/table.component';
import { AddTableComponent } from './components/lookup/add-table/add-table.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [canActivateTeam],
  },
  {
    path: 'details',
    component: DetailsComponent,
    canActivate: [canActivateTeam],
  },
  {
    path: 'lookup-list',
    component: ListComponent,
    canActivate: [canActivateTeam],
  },
  {
    path: 'lookup-table',
    component: TableComponent,
    canActivate: [canActivateTeam],
  },
  {
    path: 'add-table',
    component: AddTableComponent,
    canActivate: [canActivateTeam],
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full', // Redirect to login page if path not found
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
