<main class="dashboard-container container">
  <section class="">
    <div class="intransit-loads mb-3">
      <h2>Shipments</h2>
    </div>

    <div class="table-responsive">
      <table class="table table-borderless port-load-table">
        <thead>
          <tr>
            <th scope="col">Loadid</th>
            <th scope="col">route risk</th>
            <th scope="col">threat level</th>
            <th scope="col">exception</th>
            <th scope="col">threat management</th>
            <th scope="col">inspection</th>
            <th scope="col">date stamp</th>
            <th scope="col">time stamp</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of shipmentsList"
            (click)="showDetails('load-id-details', item.load_id)"
          >
            <td>{{ item.load_id }}</td>
            <td>{{ item.route_risk }}</td>
            <td>{{ item.threat_level }}</td>
            <td>{{ item.exception }}</td>
            <td>{{ item.threat_management }}</td>
            <td>{{ item.inspection }}</td>
            <td>{{ item.datestamp }}</td>
            <td>{{ item.timestamp }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-end mt-3 px-3">
      <ngb-pagination
        [maxSize]="5"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="totalItems"
        size="sm"
        (pageChange)="getPaginationFromServer($event)"
      >
      </ngb-pagination>
    </div>
  </section>

  <div *ngIf="showLoadIdDetails" class="seperators p-0 my-5">
    <hr />
  </div>

  <section *ngIf="showLoadIdDetails" id="load-id-details" class="">
    <article class="mb-5">
      <div class="row mb-4">
        <div class="col-lg-4">
          <div class="info-box">
            <p>Threat Mgmt</p>
            <div class="info-card pending mb-2">
              <p class="m-0">pending</p>
            </div>
            <div class="info-card-default">
              <p class="m-0">HANDOFF / / PENDING</p>
            </div>
            <hr />
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Spof Exception</p>
            <div class="info-card pending">
              <p class="m-0">Open Door</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Load status</p>
            <div class="info-card detained mb-2">
              <p class="m-0">Detained</p>
            </div>
            <div class="info-card-default">
              <p class="m-0">EN:ROUTE / / DETAINED</p>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </article>
    <div class="matrix-header adjustable-text-snapshot">
      <div class="text-center">SINGLE LOAD SNAPSHOT</div>
    </div>

    <article class="green-border mb-5">
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="info-box">
            <p>Threat Risk</p>
            <div class="info-card">
              <p class="m-0 text-red">In-progress</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Exception</p>
            <div class="info-card">
              <p class="m-0 text-red">Open Door</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Geolocation</p>
            <div class="info-card">
              <p class="m-0 text-purple">S.o.s A p i</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="info-box">
            <p>Carrier plate</p>
            <div class="info-card">
              <p class="m-0 text-purple">Mongo</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Product type</p>
            <div class="info-card">
              <p class="m-0 text-purple">Electronics</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Container id</p>
            <div class="info-card">
              <p class="m-0 text-purple">Mongo</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="info-box">
            <p>Assignment</p>
            <div class="info-card">
              <p class="m-0 text-purple">Mongo</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Carrier name</p>
            <div class="info-card">
              <p class="m-0 text-purple">pedro</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="info-box">
            <p>Consignment</p>
            <div class="info-card">
              <p class="m-0 text-purple">Mongo</p>
            </div>
          </div>
        </div>
      </div>
    </article>
    <article class="seperators mb-5">
      <div class="row">
        <div class="col-4">
          <hr />
        </div>
        <div class="col-4">
          <hr />
        </div>
        <div class="col-4">
          <hr />
        </div>
      </div>
    </article>

    <article class="red-border">
      <div class="row mb-4">
        <div class="col-lg-4" *ngFor="let item of items; let i = index">
          <div class="row">
            <div class="col-12">
              <div class="info-box">
                <p>{{ item.label }}</p>
                <!-- <hr /> -->
              </div>
            </div>
            <div
              class="{{ subItem.class }} mb-1"
              *ngFor="let subItem of item.subItems; let j = index"
              [ngClass]="
                j % 2 === 0 && item.label != 'carrier data' ? 'p-r-1' : 'p-l-1'
              "
              [class.mb-5]="
                item.label == 'carrier data'
                  ? (j + 1) % 2 === 0 && j !== item.subItems.length - 1
                  : (j + 1) % 4 === 0 && j !== item.subItems.length - 1
              "
            >
              <div class="info-box">
                <div
                  class="info-card"
                  [ngClass]="{
                    'info-card-default':
                      (item.label == 'carrier data' && j % 2 == 1) ||
                      (item.label != 'carrier data' &&
                        (j % 4 === 2 || j % 4 === 3))
                  }"
                >
                  <p class="m-0">{{ subItem.value }}</p>
                </div>
                <!-- <hr /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</main>
