import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { MustMatch } from 'src/app/services/must-match.validator';
import { SessionService } from 'src/app/services/session.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userDetails: any;
  displayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any };
  constructor(
    private themeService: ThemeService,
    private dataService: DataService,
    private sessionService: SessionService,
    private route: Router
  ) {}
  private modalService = inject(NgbModal);
  updateProfileForm: FormGroup;
  resetPasswordForm: FormGroup;

  ngOnInit(): void {
    this.userDetails = this.sessionService.getUserDetails();
    this.initializeUpdateProfileForm();
    this.initializeResetPasswordForm();
  }

  initializeUpdateProfileForm(): void {
    let textRejex = /^(?! )(.*)$/;
    this.updateProfileForm = new FormGroup({
      userId: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      firstName: new FormControl(null, [
        Validators.required,
        Validators.pattern(textRejex),
        Validators.minLength(2),
        Validators.maxLength(32),
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.pattern(textRejex),
        Validators.minLength(2),
        Validators.maxLength(32),
      ]),
      role: new FormControl(null),
      email: new FormControl(null),
      accessCount: new FormControl(null),
      createdAt: new FormControl(null),
      deleted: new FormControl(null),
      deletedAt: new FormControl(null),
      id: new FormControl(null),
      inactive: new FormControl(null),
      lastAccessTS: new FormControl(null),
      orgName: new FormControl(null),
      tenantId: new FormControl(null),
      uid: new FormControl(null),
      updatedAt: new FormControl(null),
      emailVerifyKey: new FormControl(null),
      verifyLinkSentAt: new FormControl(null),
    });
  }

  updateProfileFormPatch() {
    this.updateProfileForm.patchValue({
      userId: this.userDetails?.userId,
      role: this.userDetails?.role,
      email: this.userDetails?.email,
      firstName: this.userDetails?.firstName,
      lastName: this.userDetails?.lastName,
      accessCount: this.userDetails?.accessCount,
      createdAt: this.userDetails?.createdAt,
      deleted: this.userDetails?.deleted,
      deletedAt: this.userDetails?.deletedAt,
      id: this.userDetails?.id,
      inactive: this.userDetails?.inactive,
      lastAccessTS: this.userDetails?.lastAccessTS,
      orgName: this.userDetails?.orgName,
      tenantId: this.userDetails?.tenantId,
      uid: this.userDetails?.uid,
      updatedAt: this.userDetails?.updatedAt,
      emailVerifyKey: this.userDetails?.emailVerifyKey,
      verifyLinkSentAt: this.userDetails?.verifyLinkSentAt,
    });
  }

  initializeResetPasswordForm() {
    const passwordRejex = /^.{8,}$/;
    const maxPasswordLength = 24;
    this.resetPasswordForm = new FormGroup(
      {
        userId: new FormControl({
          value: this.userDetails?.userId,
          disabled: true,
        }),
        emailVerifyKey: new FormControl(null),
        oldPassword: new FormControl(null),
        password: new FormControl(null, [
          Validators.required,
          Validators.pattern(passwordRejex),
          Validators.maxLength(maxPasswordLength),
        ]),
        rptPassword: new FormControl(null, Validators.required),
        orgName: new FormControl(null),
      },
      { validators: MustMatch('password', 'rptPassword') }
    );
  }

  toggleTheme(): void {
    this.themeService.toggleTheme();
  }

  isLightTheme(): boolean {
    return this.themeService.isLightThemeEnabled();
  }

  open(content, check: string) {
    check === 'profile'
      ? this.updateProfileFormPatch()
      : this.initializeResetPasswordForm();
    this.modalService.open(content, {
      centered: true,
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
    });
  }

  updateProfile() {
    this.updateProfileForm.get('userId').enable();
    if (this.updateProfileForm.valid) {
      this.dataService.editUser(this.updateProfileForm.value).subscribe({
        next: (result) => {
          if (result.status === 'failed') {
            this.displayAlertMessage(result.message, 'danger', 'danger');
          } else {
            this.displayAlertMessage(result.message, 'success', 'success');
            this.userDetails.firstName = result?.props?.user['firstName'];
            this.userDetails.lastName = result?.props?.user['lastName'];
            this.sessionService.setUserDetails(this.userDetails);
            this.modalService.dismissAll();
          }
        },
        error: (error) => {
          console.error('SubmitProfileSettingData: ERROR ===>>', error);
          this.displayAlertMessage(error.message, 'danger', 'danger');
        },
      });
    } else {
      this.updateProfileForm.markAllAsTouched();
    }
    this.updateProfileForm.get('userId').disable();
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      let body = this.userDetails;
      body.oldPassword = this.resetPasswordForm.controls['oldPassword'].value;
      body.password = this.resetPasswordForm.controls['password'].value;
      body.rptPassword = this.resetPasswordForm.controls['rptPassword'].value;
      this.dataService.changePassword(body).subscribe({
        next: (result) => {
          if (result.failed) {
            this.displayAlertMessage(
              'Current Password is Wrong!',
              'error',
              'danger'
            );
          } else {
            this.dismissModal();
            this.displayAlertMessage('Success', 'success', 'success');
          }
        },
        error: (error) => {
          this.displayAlertMessage(
            'Current Password is Wrong!',
            'error',
            'danger'
          );
        },
        complete: () => {},
      });
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  logout() {
    localStorage.clear();
    this.route.navigateByUrl('/authentication/login');
  }

  dismissModal() {
    this.modalService.dismissAll();
    this.updateProfileForm.reset();
    this.resetPasswordForm.reset();
    this.initializeUpdateProfileForm();
    this.initializeResetPasswordForm();
  }

  // ************************** ALERT MESSAGES ***************************/
  displayAlertMessage(incomingMessage, incomingResponseType, incomingColor) {
    this.displayErrorBlock = true;
    this.resMessage = {
      message: incomingMessage,
      responseType: incomingResponseType,
      color: incomingColor,
    };
    setTimeout(() => {
      this.displayErrorBlock = false;
    }, 3000);
  }
}
