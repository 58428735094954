import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  isAlreadyExists: boolean = false;
  constructor(private router: Router, private dataService: DataService) {}
  private modalService = inject(NgbModal);
  rows: number = 5;
  isClicked = false;
  columns: number = 5;
  tableName: string = '';
  lookupTables: any = [];

  ngOnInit(): void {
    this.getLookupTables();
  }

  getLookupTables() {
    let body = {};
    this.dataService.getLookupTables(body).subscribe({
      next: (result) => {
        console.log(result);

        this.lookupTables = result.data;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
  openModal(addTable: any) {
    this.modalService.open(addTable, { centered: true });
  }
  
  onAddTable() {
    const lowerCaseLookupTables = this.lookupTables.map(table => table.toLowerCase());
    const trimmedTableName = this.tableName.toLowerCase().trim();
  
    if (this.columns != 0 && this.rows != 0 && trimmedTableName !== '' && !lowerCaseLookupTables.includes(trimmedTableName) && this.isTableNameValid()) {
      this.isClicked = false;
      this.isAlreadyExists = false;
      this.router.navigate(['/add-table'], {
        queryParams: {
          columns: this.columns,
          rows: this.rows,
          name: this.tableName,
        },
      });
      this.modalService.dismissAll();
    } else if (trimmedTableName !== '' && lowerCaseLookupTables.includes(trimmedTableName)) {
      this.isAlreadyExists = true;
    } else {
      this.isClicked = true;
      this.isAlreadyExists = false;
    }
  }
  
  isTableNameValid(): boolean {
    return /^[a-zA-Z]/.test(this.tableName);
  }
  

  cancelChanges(){
this.tableName = '';
this.columns = 5;
this.rows = 5;
    this.modalService.dismissAll();
  }
}
