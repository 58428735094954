import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  displayErrorBlock: boolean = false;
  loader: boolean = false;
  resMessage: { message: any; responseType: any; color: any; };

  constructor(private authservice: AuthService, private router : Router) { }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      tenantName: new FormControl("")
    })
  }
  
  forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      this.loader = true;
      this.authservice.forgotPassword(this.forgotPasswordForm.value).subscribe({
        next: (result) => {
          if (result.status == 'success') {
            this.displayAlertMessage('Reset password mail has been sent', 'success', 'success');
            setTimeout(() => { this.router.navigateByUrl('/login') 
            this.forgotPasswordForm.reset();
            this.loader = false;
          }, 2000);
          }
          else {
            this.displayAlertMessage(result.message, 'error', 'danger');
            this.loader = false;
          }
        },
        error: (error) => {
          this.displayAlertMessage('Error occured please try again later', 'error', 'danger');
          console.error("cForgotPasswordComponent_SendForgotPasswordRequest: Error ==>>>", error);
          this.loader = false
        }
      })
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }

     // -------------------- ALERT MESSAGES --------------------
     displayAlertMessage(incommingMessage, incommingResponseType,incommingColor) {
      this.displayErrorBlock = true
      this.resMessage = {
        message: incommingMessage,
        responseType : incommingResponseType,
        color :  incommingColor
      };
      setTimeout(() => { this.displayErrorBlock = false; }, 3000);
    }
}
