<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
<div class="bg">
  <div class="auth-card p-5">
    <div class="">
      <div class="">
        <div class="text-center">
          <img
            class="text-center"
            src="../../../../assets/images/logo.png"
            alt=""
          />
        </div>
        <h2 class="text-center mb-4 mt-4">Login</h2>
        <form class="Input-form mt-4" [formGroup]="loginForm">
          <div>
            <div class="form-outline mb-4">
              <div class="form-outline mb-4">
                <div class="input-box">
                  <i class="fa-light fa-user"></i>
                  <input
                    formControlName="userId"
                    type="text"
                    placeholder="User Id"
                    class="form-control"
                  />
                  <small
                    class="text-danger font-12"
                    *ngIf="loginForm.get('userId')?.errors?.['required'] && loginForm.get('userId')?.touched"
                    >User Id is required</small
                  >
                </div>
              </div>
            </div>

            <div class="form-outline mb-4">
              <div class="input-box">
                <i class="fa-light fa-lock"></i>
                <input
                  type="password"
                  class="form-control"
                  type="password"
                  placeholder="Password"
                  formControlName="password"
                />
                <small
                  class="text-danger font-12"
                  *ngIf="loginForm.get('password')?.errors?.['required'] && loginForm.get('password')?.touched"
                  >Password is required</small
                >
              </div>
            </div>
          </div>

          <div class="mb-4 text-end">
            <a
              class="link"
              href="javascript:void(0)"
              [routerLink]="['/forgot-password']"
              >Forgot password?</a
            >
          </div>
          <div class="button">
            <button
              (click)="loader ? '' : sendLoginRequest()"
              type="submit"
              class="btn py-2 d-block btn-primary btn-lg w-100 position-relative"
            >
              <div class="lds-roller" *ngIf="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span [ngClass]="!loader ? '' : 'not-visible'">LOGIN</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
