import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MustMatch } from 'src/app/services/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit{
  // passwordVisible:boolean=false;
  // rptPasswordVisible:boolean=false;
  resetPasswordForm: FormGroup;
  loader: boolean = false;
  emailVerifyKey: any;
  orgVerifyKey: any;
  displayErrorBlock: boolean = false;
  resMessage: { message: any; responseType: any; color: any; };
  constructor (private authservice: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {}
  ngOnInit(): void { 
    this.getKeyFromRoute()
    this.initializeResetPasswordForm();
  }
  getKeyFromRoute() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.emailVerifyKey = params['k'];
      this.orgVerifyKey = params['o'];
    })
  }

  // togglePasswordVisibility() {
  //   this.passwordVisible = !this.passwordVisible;
  // }

  // toggleRptPasswordVisibility() {
  //   this.rptPasswordVisible = !this.rptPasswordVisible;
  // }

  initializeResetPasswordForm() {
    const passwordRejex = /^.{8,}$/;
    const maxPasswordLength = 24;
    this.resetPasswordForm = new FormGroup({
      emailVerifyKey: new FormControl(this.emailVerifyKey),
      password: new FormControl('', [ Validators.required, Validators.pattern(passwordRejex), Validators.maxLength(maxPasswordLength) ]),
      rptPassword: new FormControl('', Validators.required),
      orgName: new FormControl(this.orgVerifyKey)
    }, { validators: MustMatch('password', 'rptPassword') });
  }
  
  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loader = true;
      this.authservice.resetPassword(this.resetPasswordForm.value).subscribe({
          next: (result) => {
            if (result.status == 'success') {
              this.displayAlertMessage('Password reset successfully', 'success', 'success');
              setTimeout(() => { this.router.navigateByUrl('/login') 
              this.loader = false;
            }, 3000);
            } else {
              this.loader = false;
              this.displayAlertMessage(result.message, 'error', 'danger');
            }
          },
          error: (error) => {
            this.displayAlertMessage(JSON.parse(error.message).detailMessage, 'error', 'danger');
            this.loader = false;
          }
        })
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

   // -------------------- ALERT MESSAGES --------------------
   displayAlertMessage(incommingMessage, incommingResponseType,incommingColor) {
    this.displayErrorBlock = true
    this.resMessage = {
      message: incommingMessage,
      responseType : incommingResponseType,
      color :  incommingColor
    };
    setTimeout(() => { this.displayErrorBlock = false; }, 3000);
  }

}
