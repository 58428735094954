import { Component, ElementRef, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { __values } from 'tslib';

interface MatrixCell {
  id: string;
  status: string;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  matrixData: MatrixCell[] = [];
  items: any[] = [
    {
      label: 'Load assign data',
      subItems: [
        {
          class: 'col-lg-6',
          value: 'Shipper',
        },
        {
          class: 'col-lg-6',
          value: 'Sec cert',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'assign',
        },
        {
          class: 'col-lg-6',
          value: 'timestamp',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'seaport',
        },
        {
          class: 'col-lg-6',
          value: 'port code',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
      ],
    },
    {
      label: 'carrier data',
      subItems: [
        {
          class: 'col-lg-12',
          value: 'carrier',
        },
        {
          class: 'col-lg-12',
          value: '-',
        },
        {
          class: 'col-lg-12',
          value: 'sec cert',
        },
        {
          class: 'col-lg-12',
          value: '-',
        },
        {
          class: 'col-lg-12',
          value: 'driver"s license',
        },
        {
          class: 'col-lg-12',
          value: '-',
        },
      ],
    },
    {
      label: 'load cnsig data',
      class: 'col-lg-6',
      subItems: [
        {
          class: 'col-lg-6',
          value: 'receiver',
        },
        {
          class: 'col-lg-6',
          value: 'Sec cert',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'consign',
        },
        {
          class: 'col-lg-6',
          value: 'time stamp',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: 'lane id',
        },
        {
          class: 'col-lg-6',
          value: 'load id',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
        {
          class: 'col-lg-6',
          value: '-',
        },
      ],
    },
  ];
  showLoadIdDetails: boolean = false;
  dashboardData: any;
  displayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any };
  loadIdsWithExceptions: any[] = [];
  loadDetails: any;

  constructor(private el: ElementRef, private dataService: DataService) {}
  ngOnInit(): void {
    this.getDashboardData('MIAMI');
  }

  generateMatrixData(incomingMatrixData) {
    this.matrixData = [];
    for (let i = 0; i < incomingMatrixData?.length; i++) {
      const status = this.getStatus(incomingMatrixData[i]);
      this.matrixData.push({ id: incomingMatrixData[i], status });
    }
  }

  transformData(realData: any): any[] {
    return [
      {
        label: 'Load assign data',
        subItems: [
          { class: 'col-lg-6', value: 'Shipper' },
          { class: 'col-lg-6', value: 'Sec cert' },
          { class: 'col-lg-6', value: realData.loadAssignData.shipper },
          { class: 'col-lg-6', value: realData.loadAssignData.sec_cert },
          { class: 'col-lg-6', value: 'Assign' },
          { class: 'col-lg-6', value: 'Timestamp' },
          { class: 'col-lg-6', value: realData.loadAssignData.assign },
          { class: 'col-lg-6', value: realData.loadAssignData.timestamp },
          { class: 'col-lg-6', value: 'Seaport' },
          { class: 'col-lg-6', value: 'Port code' },
          { class: 'col-lg-6', value: realData.loadAssignData.seaport },
          { class: 'col-lg-6', value: realData.loadAssignData.port_code },
        ],
      },
      {
        label: 'carrier data',
        subItems: [
          { class: 'col-lg-12', value: 'Carrier' },
          { class: 'col-lg-12', value: realData.carrierData.carrier },
          { class: 'col-lg-12', value: 'Sec cert' },
          { class: 'col-lg-12', value: realData.carrierData.sec_cert },
          { class: 'col-lg-12', value: "Driver's license" },
          {
            class: 'col-lg-12',
            value: realData.carrierData["driver's_licence"],
          },
        ],
      },
      {
        label: 'Load cnsig data',
        subItems: [
          { class: 'col-lg-6', value: 'Receiver' },
          { class: 'col-lg-6', value: 'Sec cert' },
          { class: 'col-lg-6', value: realData.loadConsignData.receiver },
          { class: 'col-lg-6', value: realData.loadConsignData.sec_cert },
          { class: 'col-lg-6', value: 'Consign' },
          { class: 'col-lg-6', value: 'Time stamp' },
          { class: 'col-lg-6', value: realData.loadConsignData.consign },
          { class: 'col-lg-6', value: realData.loadConsignData.timestamp },
          { class: 'col-lg-6', value: 'Lane id' },
          { class: 'col-lg-6', value: 'Load id' },
          { class: 'col-lg-6', value: realData.loadConsignData.lane_id },
          { class: 'col-lg-6', value: realData.loadConsignData.load_id },
        ],
      },
    ];
  }

  // getStatus(id): string {
  //   const statuses = ['text-yellow', 'text-red', 'text-green'];
  //   return statuses[Math.floor(Math.random() * statuses.length)];
  // }

  getStatus(id: string): string {
    const statusMap: { [key: string]: string } = {
      MID: 'text-yellow text-yellow-matrix',
      LOW: 'text-green',
      HIGH: 'text-red',
    };

    const match = id.match(/(MID|LOW|HIGH)-\d+/);
    if (match) {
      const status = match[1];
      return statusMap[status] || '';
    }

    return '';
  }

  showDetails(sectionId: string, incomingMatrixId: string) {
    this.getLoadDetails(incomingMatrixId);
    this.showLoadIdDetails = true;
    setTimeout(() => {
      const element = this.el.nativeElement.querySelector(`#${sectionId}`);
      if (element) {
        const targetScrollPosition = element.offsetTop - 100;
        window.scrollTo({
          top: targetScrollPosition,
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  getDashboardDataThroughInput(incomingPortOfEntry) {
    const inputElement = incomingPortOfEntry.target as HTMLInputElement;
    this.getDashboardData(inputElement.value);
    inputElement.blur();
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.dashboardData.targetPortOfEntry = input.value.toUpperCase();
  }

  getDashboardData(incomingPortOfEntry) {
    this.dataService
      .getDashboardData({ portOfEntry: incomingPortOfEntry })
      .subscribe({
        next: (result) => {
          if (result.status === 'failed') {
            this.displayAlertMessage(result.message, 'error', 'danger');
            let tempName = this.dashboardData?.targetPortOfEntry;
            this.dashboardData = null;
            this.matrixData = [];
            this.dashboardData = { targetPortOfEntry: tempName };
          } else {
            this.dashboardData = result;
            this.loadIdsWithExceptions =
            this.dashboardData.loadIdsWithExceptions?.split(',');
            this.generateMatrixData(this.loadIdsWithExceptions);
          }
          this.showLoadIdDetails = false;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  getLoadDetails(incomingid) {
    this.dataService.getLoadDetails({ loadId: incomingid }).subscribe({
      next: (result) => {
        if (result.status === 'failed') {
          this.displayAlertMessage(result.message, 'error', 'danger');
        } else {
          this.loadDetails = result?.data;
          this.items = this.transformData(this.loadDetails);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  // -------------------- ALERT MESSAGES --------------------
  displayAlertMessage(incommingMessage, incommingResponseType, incommingColor) {
    this.displayErrorBlock = true;
    this.resMessage = {
      message: incommingMessage,
      responseType: incommingResponseType,
      color: incommingColor,
    };
    setTimeout(() => {
      this.displayErrorBlock = false;
    }, 3000);
  }
}
