import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  setUserProfile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/_service/um/editUser',
      body
    );
  }

  changePassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/_service/um/changeMyPassword',
      body
    );
  }

  editUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/_service/um/editUser',
      body
    );
  }

  getLookupTables(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL +
        '/ds/function/shared/LookUpTablesV1/GetAllLookUpTables',
      body
    );
  }
  getLookupTableDetail(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL +
        '/ds/function/shared/LookUpTablesV1/GetLookUpTableData',
      body
    );
  }
  editLookupTable(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/ds/function/shared/TableActionsV1/EditTable',
      body
    );
  }

  getAllLoads(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL +
        '/ds/function/shared/GetAllLoadsMock/GetTableAllLoads',
      body
    );
  }

  addLookupTable(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/ds/function/shared/TableActionsV1/AddTable',
      body
    );
  }

  getDashboardData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/ds/function/shared/GetMockDashboadV1/GetDashboardData',
      body
    );
  }

  getLoadDetails(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/ds/function/shared/GetMockLoadDetailsV1/GetLoadDetails',
      body
    );
  }

  deleteTable(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/ds/function/shared/TableActionsV1/DeleteTable',
      body
    );
  }

  


}
