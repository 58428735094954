import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private lightThemeClass = 'light-theme';

  constructor() {}

  enableLightTheme(): void {
    document.body.classList.add(this.lightThemeClass);
  }

  disableLightTheme(): void {
    document.body.classList.remove(this.lightThemeClass);
  }

  isLightThemeEnabled(): boolean {
    return document.body.classList.contains(this.lightThemeClass);
  }

  toggleTheme(): void {
    if (this.isLightThemeEnabled()) {
      this.disableLightTheme();
    } else {
      this.enableLightTheme();
    }
  }
}
