<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
<div class="bg">
  <div class="auth-card p-5">
    <div class="">
      <div class="">
        <div class="text-center">
          <img
            class="text-center"
            src="../../../../assets/images/logo.png"
            alt=""
          />
        </div>
        <h2 class="text-center mb-4 mt-4">Reset Password</h2>
        <p class="text-center">
          Enter a new password to reset the password on your account. We'll ask
          for this password whenever you login
        </p>
        <form class="Input-form mt-4" [formGroup]="resetPasswordForm">
          <div>
            <div class="form-outline mb-4">
              <div class="form-outline mb-4">
                <div class="input-box">
                  <i class="fa-light fa-lock"></i>
                  <input
                    type="password"
                    placeholder="Password"
                    class="form-control"
                    formControlName="password"
                  />
                  <div
                    *ngIf="
                      resetPasswordForm.controls['password'].hasError(
                        'required'
                      ) && resetPasswordForm.controls['password'].touched
                    "
                    class="text-danger font-13"
                  >
                    Password is required
                  </div>
                  <div
                    *ngIf="
                      resetPasswordForm.controls['password'].hasError(
                        'pattern'
                      ) && resetPasswordForm.controls['password'].touched
                    "
                    class="text-danger font-13"
                  >
                    Password needs to be at least 8 characters long.
                  </div>
                  <div
                    *ngIf="
                      resetPasswordForm.get('password').hasError('maxlength') &&
                      resetPasswordForm.get('password').touched
                    "
                    class="text-danger font-13"
                  >
                    Password cannot exceed 24 characters.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-outline mb-4">
              <div class="form-outline mb-4">
                <div class="input-box">
                  <i class="fa-light fa-lock"></i>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    class="form-control"
                    formControlName="rptPassword"
                  />
                  <div
                    *ngIf="
                      resetPasswordForm.controls['rptPassword'].hasError(
                        'required'
                      ) && resetPasswordForm.controls['rptPassword'].touched
                    "
                    class="text-danger font-13"
                  >
                    Confirm password is required
                  </div>
                  <div
                    *ngIf="resetPasswordForm.controls['rptPassword'].errors?.['mustMatch'] && resetPasswordForm.controls['rptPassword'].dirty"
                    class="text-danger font-13"
                  >
                    Password and confirm password not matched
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="button">
            <button
              type="submit"
              class="btn py-2 d-block btn-primary btn-lg w-100 position-relative"
              (click)="loader ? '' : resetPassword()"
            >
              <div class="lds-roller" *ngIf="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span [ngClass]="!loader ? '' : 'not-visible'">Reset</span>
            </button>
          </div>
          <div class="text-center mt-3">
            <a class="link" href="javascript:void(0)" [routerLink]="['/login']">
              <i class="fa-light fa-arrow-turn-left mx-2"></i>
              Back to Login</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
