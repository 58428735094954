<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="cursor-pointer" routerLink="/dashboard">
      <img src="../../../../assets/images/logo.png" height="50" alt="" />
      <img src="../../../../assets/images/logo-text.png" alt="" />
    </div>
    <div class="text-white d-flex gap-3">
      <i
        *ngIf="isLightTheme()"
        class="fa-sharp fa-solid fa-moon"
        (click)="toggleTheme()"
      ></i>
      <i
        *ngIf="!isLightTheme()"
        class="fa-light fa-sun-bright"
        (click)="toggleTheme()"
      ></i>
      <div ngbDropdown class="dropdown">
        <i ngbDropdownToggle class="fa-light fa-circle-user"></i>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button
            class="border-bottom py-3 pe-none d-flex gap-2 align-items-center"
            ngbDropdownItem
          >
            <span class="profile-avatar">Z</span>
            <div class="d-flex flex-column">
              <span>
                {{
                  userDetails?.firstName &&
                  userDetails?.lastName &&
                  userDetails?.firstName.length +
                    userDetails?.lastName.length <=
                    15
                    ? userDetails?.firstName + " " + userDetails?.lastName
                    : (userDetails.userId | titlecase)
                }}</span
              >
              <small class="fs-10">{{ userDetails?.role | titlecase }}</small>
            </div>
          </button>
          <button
            class="my-1"
            ngbDropdownItem
            (click)="open(profile, 'profile')"
          >
            <i class="fa-light fa-user"></i>
            Profile
          </button>
          <button
            class="my-1"
            ngbDropdownItem
            (click)="open(password, 'password')"
          >
            <i class="fa-light fa-lock"></i>
            Change Password
          </button>
          <button class="my-1" ngbDropdownItem routerLink="/lookup-list">
            <i class="fa-light fa-table"></i>
            Lookup Tables
          </button>
          <button class="my-1" ngbDropdownItem (click)="logout()">
            <i class="fa-light fa-left-from-bracket"></i>
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #profile let-modal>
  <div class="modal-header justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">Profile</h4>
    <i class="fa-light fa-xmark" (click)="dismissModal()"></i>
  </div>
  <div class="modal-body">
    <form [formGroup]="updateProfileForm">
      <div class="mb-3">
        <div class="form-group mb-4">
          <label class="form-label">User ID</label>
          <input
            type="text"
            placeholder="User ID"
            class="form-control"
            formControlName="userId"
          />
        </div>
        <div class="form-group mb-4">
          <label class="form-label">First Name</label>
          <input
            type="text"
            placeholder="Firstname"
            class="form-control"
            formControlName="firstName"
          />
          <small
            *ngIf="updateProfileForm.controls['firstName'].hasError('pattern')"
            class="text-danger"
            >First character cannot be space
          </small>
          <small
            *ngIf="
              (!updateProfileForm.controls['firstName'].hasError('required') ||
                updateProfileForm.controls['firstName'].touched) &&
              !updateProfileForm.controls['firstName'].hasError('pattern') &&
              (updateProfileForm.get('firstName')?.value?.length <= 1 ||
                updateProfileForm.get('firstName')?.value?.length >= 33)
            "
            class="text-danger font-14"
          >
            First Name must be between 2-32 characters.</small
          >
        </div>
        <div class="form-group mb-4">
          <label class="form-label">Last Name</label>
          <input
            type="text"
            placeholder="Lastname"
            class="form-control"
            formControlName="lastName"
          />
          <small
            *ngIf="updateProfileForm.controls['lastName'].hasError('pattern')"
            class="text-danger"
            >First character cannot be space
          </small>
          <small
            *ngIf="
              (!updateProfileForm.controls['lastName'].hasError('required') ||
                updateProfileForm.controls['lastName'].touched) &&
              !updateProfileForm.controls['lastName'].hasError('pattern') &&
              (updateProfileForm.get('lastName')?.value?.length <= 1 ||
                updateProfileForm.get('lastName')?.value?.length >= 33)
            "
            class="text-danger font-14"
          >
            Last Name must be between 2-32 characters.</small
          >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="updateProfile()">
      Update
    </button>
  </div>
</ng-template>

<ng-template #password let-modal>
  <div class="modal-header justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">Profile</h4>
    <i class="fa-light fa-xmark" (click)="dismissModal()"></i>
  </div>
  <div class="modal-body">
    <form [formGroup]="resetPasswordForm">
      <div class="mb-3">
        <div class="form-group mb-4">
          <label class="form-label">User ID</label>
          <input
            type="text"
            placeholder="User ID"
            class="form-control"
            formControlName="userId"
          />
        </div>
        <div class="form-group mb-4">
          <label class="form-label">Current Password</label>
          <input
            type="password"
            placeholder="Password"
            class="form-control"
            formControlName="oldPassword"
          />
          <small
            *ngIf="
              resetPasswordForm.controls['oldPassword'].hasError('required') &&
              resetPasswordForm.controls['oldPassword'].touched
            "
            class="text-danger"
            >Current password is required</small
          >
        </div>

        <div class="form-group mb-4">
          <label class="form-label">New Password</label>
          <input
            type="password"
            placeholder="New Password"
            class="form-control"
            formControlName="password"
          />
          <small
            *ngIf="
              resetPasswordForm.controls['password'].hasError('required') &&
              resetPasswordForm.controls['password'].touched
            "
            class="text-danger"
            >New password is required</small
          >
          <small
            *ngIf="
              resetPasswordForm.controls['password'].hasError('pattern') &&
              resetPasswordForm.controls['password'].touched
            "
            class="text-danger"
            >Password needs to be at least 8 characters long.</small
          >
          <small
            *ngIf="
              resetPasswordForm.get('password').hasError('maxlength') &&
              resetPasswordForm.get('password').touched
            "
            class="text-danger"
          >
            Password cannot exceed 24 characters.</small
          >
        </div>

        <div class="form-group mb-4">
          <label class="form-label">Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm Password"
            class="form-control"
            formControlName="rptPassword"
          />
          <small
            *ngIf="
              resetPasswordForm.controls['rptPassword'].hasError('required') &&
              resetPasswordForm.controls['rptPassword'].touched
            "
            class="text-danger"
            >Confirm new password is required</small
          >
          <small
            *ngIf="resetPasswordForm.controls['rptPassword'].errors?.['mustMatch'] && resetPasswordForm.controls['rptPassword'].dirty"
            class="text-danger"
            >Password and confirm new password not matched</small
          >
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="resetPassword()">
      Update
    </button>
  </div>
</ng-template>
