import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  params: any;
  list: any[] = [];
  columnNames: any[] = [];
  unwantedNames: string[] = [
    'updatedAt',
    'id',
    'deleted',
    'deletedAt',
    'uid',
    'createdAt',
  ];
  filteredColumnNames: string[] = [];
  page = 1;
  pageSize = 20; 
  collectionSize = 0;  

  isEditMode = false;
  updatedRows: any[] = [];
  displayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any; };

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router
  ) {}

  private modalService = inject(NgbModal);

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      console.log(this.params);
      this.getLookupTableDetail(this.params.name, this.page, this.pageSize);
    });
  }

  getLookupTableDetail(tableName: string, page: number, pageSize: number) {
    let body = {
      tableName: tableName,
      start: (page - 1) * pageSize + 1,
      size: pageSize
    };
    this.dataService.getLookupTableDetail(body).subscribe({
      next: (result) => {
        if (result.status === 'failed') {
          this.displayAlertMessage(result.message, 'error', 'danger'); 
        } else {
          this.list = result?.data?.data;
          this.collectionSize = result?.data?.total || 0; 
          this.columnNames = Object.keys(result?.data?.data[0]);
        this.filteredColumnNames = this.columnNames.filter(
          (name) => !this.unwantedNames.includes(name)
          );
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  trackChanges(rowIndex: number) {
    if (!this.updatedRows.includes(this.list[rowIndex])) {
      this.updatedRows.push(this.list[rowIndex]);
    }
  }
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
    if (!this.isEditMode) {
      // Reset the updatedRows list when exiting edit mode
      this.updatedRows = [];
    }
  }

  saveChanges() {
    console.log('Updated Rows:', this.updatedRows);
    // this.toggleEditMode();
    if (this.updatedRows.length > 0) {
      console.log('Updated Rows:', this.updatedRows);
      let body = {
        tableName: this.params.name,
        data: this.updatedRows,
      };
      this.dataService.editLookupTable(body).subscribe({
        next: (result) => {
          if(result.status=='failed'){
            this.displayAlertMessage(result.message, 'error', 'danger');
          } else {          // Update the table data with the new values
          this.updatedRows.forEach((row) => {
            const rowIndex = this.list.findIndex((item) => item.id === row.id);
            this.list[rowIndex] = { ...row };
          });
          this.updatedRows = [];
          this.toggleEditMode();
          this.displayAlertMessage('Table updated successfully', 'success', 'success');
        }
        },
        error: (error) => {
          console.error(error);
        },
      });
    } else {
      this.displayAlertMessage('No changes were made to the table data', 'error', 'danger');
    }
    // this.toggleEditMode();
  }

  deleteTable() {
    this.dataService.deleteTable({"tableName":this.params?.name}).subscribe({
      next: (result) => {
        if(result.status=='failed'){
          this.displayAlertMessage(result.message, 'error', 'danger');
        } else { 
        this.displayAlertMessage('Table deleted successfully', 'success', 'success');
        setTimeout(() => {
          this.router.navigate(['/lookup-list']);
        }, 1200);
      }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  cancelChanges() {
    // Optionally handle any additional logic on cancel
    this.toggleEditMode();
  }

  cancelEdits(cancel: any) {
    this.modalService.open(cancel, { centered: true });
  }

  deleteTableModal(deleteT: any) {
    this.modalService.open(deleteT, { centered: true, backdrop: 'static' });
  }

  onPageChange(page: number) {
    this.page = page;
    this.getLookupTableDetail(this.params.name, this.page, this.pageSize);
  }

       // -------------------- ALERT MESSAGES --------------------
       displayAlertMessage(incommingMessage, incommingResponseType,incommingColor) {
        this.displayErrorBlock = true
        this.resMessage = {
          message: incommingMessage,
          responseType : incommingResponseType,
          color :  incommingColor
        };
        setTimeout(() => { this.displayErrorBlock = false; }, 3000);
      }
}
