import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  // set access token
  setAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, JSON.stringify(oData))
  }
  // get access token
  getAccessToken() {
    return JSON.parse(localStorage.getItem(environment.AccessToken))
  }

  // set user details
  setUserDetails(oData: any) {
    localStorage.setItem('lsclaim', btoa(JSON.stringify(oData)));
  }

  // get user details
  getUserDetails() {
    const encodedData = localStorage.getItem('lsclaim');
    if (encodedData) return JSON.parse(atob(encodedData));
  }

}

