<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
<section id="dashboard-section" class="container dashboard-container mb-4">
  <div class="row mb-4">
    <div class="col-lg-4">
      <div class="info-box">
        <p>Target port of entry</p>
        <div class="info-card">
          <!-- <p class="m-0">Felixtowe</p> -->
          <input (input)="onInput($event)" type="text" [value]="dashboardData?.targetPortOfEntry" (focusout)="getDashboardDataThroughInput($event)" (keydown.enter)="getDashboardDataThroughInput($event)" class="border-0 form-control text-purple font-12" />
        </div>
        <hr />
      </div>
    </div>

    <div class="col-lg-4">
      <div class="info-box">
        <p>TARGET PORT LOADS</p>
        <div
          class="info-card cursor-pointer elevated-card"
          routerLink="/details"
        >
          <p class="m-0 text-purple">{{dashboardData?.targetPortLoads || '-'}}</p>
        </div>
        <hr />
      </div>
    </div>

    <div class="col-lg-4">
      <div class="info-box">
        <p>GEOSPATIAL THREAT BASELINE</p>
        <div class="info-card"  >
          <p class="m-0" [ngClass]="
          dashboardData?.geospatialThreatBaseline === 'HIGH' ? 'text-red' : 
          (dashboardData?.geospatialThreatBaseline === 'MED' ? 'text-yellow' : 
          (dashboardData?.geospatialThreatBaseline === 'LOW' ? 'text-green' : '')
        )">{{dashboardData?.geospatialThreatBaseline || '-'}}</p>
        </div>
        <hr />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4">
      <div class="info-box">
        <p>Loads with Exceptions</p>
        <div class="info-card">
          <p class="m-0 text-yellow">{{dashboardData?.loadsWithExceptions || '-'}}</p>
        </div>
        <hr />
      </div>
    </div>

    <!-- <div class="col-lg-4">
        <div class="info-box">
          <p>IN-TRANSIT LOADS</p>
          <div class="info-card">
            <p class="m-0">950</p>
          </div>
          <hr>
        </div>
      </div> -->

    <div class="col-lg-4 offset-lg-4">
      <div class="info-box">
        <p>Alpha Threat Loads</p>
        <div class="info-card">
          <p class="m-0 text-red">{{dashboardData?.alphaThreatLoads || '-'}}</p>
        </div>
        <hr />
      </div>
    </div>
  </div>

  <div class="matrix-header adjustable-text">
    <div class="text-center">Load Matrix</div>
  </div>
  <div class="matrix-container m-auto">
    <div class="row">
      <div class="col-md-2" *ngFor="let cell of matrixData; let i = index">
        <div class="matrix-cell">
          <span
            class="load-id"
            (click)="showDetails('load-id-details', cell.id)"
            [ngClass]="cell.status"
            >{{ cell.id }}</span
          >
          <hr />
          <!-- <div *ngIf="(i + 1) % 10 === 0" class="matrix-row"></div> -->
        </div>
      </div>
    </div>
    <div *ngIf="matrixData?.length <=0" class="text-center text-grey"> No data found</div>
    <!-- <div class="matrix">
      
    </div> -->
  </div>
</section>

<section
  *ngIf="showLoadIdDetails"
  id="load-id-details"
  class="container dashboard-container"
>
  <article class="mb-5">
    <div class="row mb-4">
      <div class="col-lg-4">
        <div class="info-box">
          <p>Threat Mgmt</p>
          <div class="info-card pending mb-2">
            <p class="m-0">pending</p>
          </div>
          <div class="info-card-default">
            <p class="m-0">HANDOFF / / PENDING</p>
          </div>
          <hr />
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Spof Exception</p>
          <div class="info-card pending">
            <p class="m-0">Open Door</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Load status</p>
          <div class="info-card detained mb-2">
            <p class="m-0">Detained</p>
          </div>
          <div class="info-card-default">
            <p class="m-0">EN:ROUTE / / DETAINED</p>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </article>
  <div class="matrix-header adjustable-text-snapshot">
    <div class="text-center">SINGLE LOAD SNAPSHOT</div>
  </div>

  <article class="green-border mb-5">
    <div class="row mb-5">
      <div class="col-lg-4">
        <div class="info-box">
          <p>Threat Risk</p>
          <div class="info-card">
            <p class="m-0 text-red">{{loadDetails?.threatRisk}}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Exception</p>
          <div class="info-card">
            <p class="m-0 text-red">{{loadDetails?.exception}}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Geolocation</p>
          <div class="info-card">
            <p class="m-0 text-purple">{{loadDetails?.geolocation}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-4">
        <div class="info-box">
          <p>Carrier plate</p>
          <div class="info-card">
            <p class="m-0 text-purple">{{loadDetails?.carrierPlate}}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Product type</p>
          <div class="info-card">
            <p class="m-0 text-purple">{{loadDetails?.productType}}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Container id</p>
          <div class="info-card">
            <p class="m-0 text-purple">{{loadDetails?.containerId}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-lg-4">
        <div class="info-box">
          <p>Assignment</p>
          <div class="info-card">
            <p class="m-0 text-purple">{{loadDetails?.assignment}}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Carrier name</p>
          <div class="info-card">
            <p class="m-0 text-purple">{{loadDetails?.carrierName}}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="info-box">
          <p>Consignment</p>
          <div class="info-card">
            <p class="m-0 text-purple">{{loadDetails?.consignment}}</p>
          </div>
        </div>
      </div>
    </div>
  </article>
  <article class="seperators mb-5">
    <div class="row">
      <div class="col-4">
        <hr />
      </div>
      <div class="col-4">
        <hr />
      </div>
      <div class="col-4">
        <hr />
      </div>
    </div>
  </article>

  <article class="red-border">
    <div class="row mb-4">
      <div class="col-lg-4" *ngFor="let item of items; let i = index">
        <div class="row">
          <div class="col-12">
            <div class="info-box">
              <p>{{ item.label }}</p>
              <!-- <hr /> -->
            </div>
          </div>
          <div
            class="{{ subItem.class }} mb-1"
            *ngFor="let subItem of item.subItems; let j = index"
            [ngClass]="
              j % 2 === 0 && item.label != 'carrier data' ? 'p-r-1' : 'p-l-1'
            "
            [class.mb-5]="
              item.label == 'carrier data'
                ? (j + 1) % 2 === 0 && j !== item.subItems.length - 1
                : (j + 1) % 4 === 0 && j !== item.subItems.length - 1
            "
          >
            <div class="info-box">
              <div
                class="info-card"
                [ngClass]="{
                  'info-card-default':
                    (item.label == 'carrier data' && j % 2 == 1) ||
                    (item.label != 'carrier data' &&
                      (j % 4 === 2 || j % 4 === 3))
                }"
              >
                <p class="m-0">{{ subItem.value }}</p>
              </div>
              <!-- <hr /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>
