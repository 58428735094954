import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  constructor(private http: HttpClient) { }
  login(body):Observable<any> {
    return this.http.post<any>(environment.BaseURL+'/_preauthsvc/user/authenticate',body)
  }

  forgotPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/forgotPassword', body)
  }
  resetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body);
  }
}
