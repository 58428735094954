import { Component, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BusyLoaderService } from './services/busyloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showHeader: boolean = false;

  constructor(
    private router: Router,
    private loaderService: BusyLoaderService,
    private renderer: Renderer2
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showHeader =
          this.router.url !== '/login' &&
          this.router.url !== '/forgot-password' &&
          this.router.url !== '/reset-password';
      }
    });

    this.loaderService.httpProgress().subscribe((status: boolean) => {
      if (status) {
        this.renderer.addClass(document.body, 'loading');
      } else {
        this.renderer.removeClass(document.body, 'loading');
      }
    });
  }
}
