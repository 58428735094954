<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
<main class="dashboard-container container">
  <section class="lookup-table">
    <div class="mb-4 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center gap-3">
        <i
          class="fa-sharp fa-light fa-arrow-turn-left"
          routerLink="/lookup-list"
        ></i>
        <h6>{{params?.name}}</h6>
      </div>
      <div class="d-flex gap-2">
        <button
        *ngIf="!isEditMode"
        class="btn btn-primary"
        (click)="deleteTableModal(delete)"
      >
          <i class="fa-regular fa-trash me-1"></i>
          Delete
      </button>
        <button
          *ngIf="!isEditMode"
          class="btn btn-primary"
          (click)="toggleEditMode()"
        >
          <i class="fa-regular fa-pen me-2"></i>
          Edit
        </button>
      </div>
    </div>

    <div class="table-responsive table-height">
      <table class="table table-borderless lookup-table">
        <thead>
          <tr>
            <th
              *ngFor="let column of filteredColumnNames; let colIndex = index"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of list; let rowIndex = index">
            <td
              *ngFor="let column of filteredColumnNames; let colIndex = index"
            >
              <ng-container *ngIf="isEditMode">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="list[rowIndex][column]"
                  (ngModelChange)="trackChanges(rowIndex)"
                />
              </ng-container>
              <ng-container *ngIf="!isEditMode">
                {{ row[column] }}
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!isEditMode" class="mt-3 d-flex justify-content-end">
      <ngb-pagination
      [collectionSize]="collectionSize"
      [(page)]="page"
      [maxSize]="5"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event)"
      aria-label="Default pagination"
    ></ngb-pagination>
    </div>

    <div *ngIf="isEditMode" class="mt-4 d-flex justify-content-end">
      <button class="btn btn-secondary me-2" (click)="cancelEdits(cancel)">
        Cancel
      </button>
      <button class="btn btn-primary" (click)="saveChanges()">Save</button>
    </div>
  </section>
</main>

<ng-template #cancel let-modal>
  <div class="modal-header justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">Cancel</h4>
    <i class="fa-light fa-xmark" (click)="modal.dismiss('Cross click')"></i>
  </div>
  <div class="modal-body">
    <p>
      This is a permanent action, Are you sure you want to cancel the edits?
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Cross click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.dismiss('Cross click'); cancelChanges()"
    >
      Yes
    </button>
  </div>
</ng-template>


<ng-template #delete let-modal>
  <div class="modal-header justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">Delete</h4>
    <i class="fa-light fa-xmark" (click)="modal.dismiss('Cross click')"></i>
  </div>
  <div class="modal-body">
    <p>
      This is a permanent action, Are you sure you want to delete this "{{params?.name}}" table?
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Cross click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.dismiss('Cross click'); deleteTable()"
    >
      Yes
    </button>
  </div>
</ng-template>