<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>
<main class="dashboard-container container">
  <section class="lookup-table">
    <div class="mb-4 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center gap-3">
        <!-- <i
          class="fa-sharp fa-light fa-arrow-turn-left"
          routerLink="/lookup-list"
        ></i> -->

        <i
        class="fa-sharp fa-light fa-arrow-turn-left"
        (click)="cancelEdits(cancel, 'back')"
      ></i>

        
        <h6>{{params?.name}}</h6>
      </div>
      <div>
        <!-- <button
          *ngIf="!isEditMode"
          class="btn btn-primary"
          (click)="toggleEditMode()"
        >
          <i class="fa-regular fa-pen me-2"></i>
          Edit
        </button> -->
      </div>
    </div>

    <div class="table-responsive table-height">
      <table class="table table-borderless lookup-table">
        <thead>
          <tr>
            <ng-container>
              <th *ngFor="let column of getColumnNames(); let i = index">
                <input
                  [(ngModel)]="editableColumnNames[i]"
                  class="form-control"
                  onkeypress="return event.charCode != 32"
                />
                <small *ngIf="isColumnNameInvalid(editableColumnNames[i]) && editableColumnNames[i]" class="font-12 text-trans-none text-danger">
                  Column name must start with an alphabet or '_'
                </small>
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of list" [class.edit-mode]="isEditMode">
            <ng-container >
              <td *ngFor="let column of getColumnNames()">
                <input [(ngModel)]="row[column]" class="form-control" />
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div *ngIf="!isEditMode" class="mt-3 d-flex justify-content-end">
      <ngb-pagination
        [collectionSize]="70"
        [(page)]="page"
        aria-label="Default pagination"
      />
    </div> -->

    <div class="mt-4 d-flex justify-content-end">
      <button class="btn btn-secondary me-2" (click)="cancelEdits(cancel, 'cancel')">
        Cancel
      </button>
      <button class="btn btn-primary" [disabled]="isAnyColumnNameInvalid()" (click)="saveChanges()">Save</button>
    </div>
  </section>
</main>

<ng-template #cancel let-modal>
  <div class="modal-header justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">{{backOrCancel == 'back' ? 'Back' : 'Cancel' }}</h4>
    <i class="fa-light fa-xmark" (click)="modal.dismiss('Cross click')"></i>
  </div>
  <div class="modal-body">
    <p>
      This is a permanent action, Are you sure you want to cancel the edit and go back to LookupTables List?
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Cross click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.dismiss('Cross click'); cancelChanges()"
    >
      Yes
    </button>
  </div>
</ng-template>
